import Plyr from 'plyr';
import objectFitImages from 'object-fit-images';
import fullpage from 'fullpage.js';
import Isotope from 'isotope-layout';
import {VerticalLines} from './vertical-lines'
import AOS from 'aos';
import LazyLoad from 'vanilla-lazyload';
import {TweenMax} from "gsap/all";

if (navigator.appVersion.indexOf("Win") != -1){
  $("html, body").addClass("windows")
}

TweenMax.set("#indicator-red-path", {rotation: -90, transformOrigin: "50% 50%"});

(() => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
})();

/*window.addEventListener('orientationchange', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});*/

const welcomeScreen = document.getElementById("welcome-screen"),
  header = document.getElementById("header"),
  homepage = document.getElementById("homepage");

/*window.addEventListener('scroll', () => {


});*/


if (!homepage) {
  $("body").addClass("inner-page");
  var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy-load",
    callback_loaded: (el) => {
      if (document.querySelector(".certificated-employees__img")) {
        setupEmployees()
      }
    }
  });
} else if (homepage) {
  if (window.outerWidth < 768) {
    var myLazyLoad = new LazyLoad({
      elements_selector: ".lazy-load"
    });
  }
}

function setupEmployees() {
  $(".certificated-employees__block").each(function () {
    var images = $(this).find("img"),
      imagesHeights = [],
      btn = $(this).find(".certificated-employees__btn"),
      first = true;

    for (let i = 0; i < images.length; i++) {
      imagesHeights.push($(images[i]).outerHeight());
    }

    if ($(window).width() > 1023) {
      TweenMax.set(images[0], {right: 100, top: 70, zIndex: 1});
      btn.click(function () {
        var timeline = new TimelineMax();

        if (first) {
          timeline
            .to(images[0], 0.3, {transformOrigin: "bottom left", rotation: 15, y: 120})
            .set(images[0], {zIndex: 0})
            .set(images[1], {zIndex: 1})
            .to(images[0], 0.3, {transformOrigin: "bottom left", rotation: 0, y: 0, top: 0, right: 15})
            .to(images[1], 0.3, {right: 100, top: 70}, "-=0.3")
        } else {
          timeline
            .to(images[1], 0.3, {transformOrigin: "bottom left", rotation: 15, y: 120})
            .set(images[1], {zIndex: 0})
            .set(images[0], {zIndex: 1})
            .to(images[1], 0.3, {transformOrigin: "bottom left", rotation: 0, y: 0, top: 0, right: 15})
            .to(images[0], 0.3, {right: 100, top: 70}, "-=0.3")
        }

        first = !first;
      });
    } else {
      TweenMax.set(images[0], {right: 0, top: 70, zIndex: 1});
      btn.click(function () {
        var timeline = new TimelineMax({force3D: true});

        if (first) {
          timeline
            .to(images[0], 0.3, {y: imagesHeights[1] - 40})
            .set(images[0], {zIndex: 0})
            .set(images[1], {zIndex: 1})
            .to(images[0], 0.3, {y: 0, top: 0, left: 0})
            .to(images[1], 0.3, {left: 0, top: 70}, "-=0.3")
        } else {
          timeline
            .to(images[1], 0.3, {y: imagesHeights[0] - 40})
            .set(images[1], {zIndex: 0})
            .set(images[0], {zIndex: 1})
            .to(images[1], 0.3, {y: 0, top: 0, left: 0})
            .to(images[0], 0.3, {left: 0, top: 70}, "-=0.3")
        }

        first = !first;
      });
    }


  });
}

$("document").ready(function () {

  /*$(".article-page__content p").each(function () {
    if ($(this).text() === '') {
      console.log($(this));
      console.log("empty");
    }
  });*/

  setTimeout(function () {
    $(".article-page__anchors a").on('click', function (event) {
      var hash = this.hash, offset = $("#header").outerHeight();
      if (hash !== "") {
        event.preventDefault();
        TweenMax.to(window, 0.8, {scrollTo: {y: hash, offsetY: offset + 50, autoKill: false}});
        //$('html,body').animate({scrollTop: $(hash).offset().top},'slow');
      }
    });
  }, 1000);

  $(".hidden-section__copy-inner").html($(".hidden-section").html());

  if ($(".aos-allow").length) {
    AOS.init({
      once: true
    });

    /*$(window).resize(function () {
        AOS.refresh();
    });*/
  }
  var packmanTimeline = new TimelineMax({
      repeat: -1, repeatDelay: 2, paused: true
    }), packmanWhiteList = [],
    packmanCounter = 0;

  (function () {
    var $container = $(".packman-container"),
      contWidth = $container.outerWidth(),
      contHeight = $container.outerHeight(),
      dotWidth = 7,
      dotHeight = 7,
      dotGutter = 18,
      dotVertCount = Math.round(contHeight / (dotHeight + dotGutter)),
      dotHorCount = Math.round(contWidth / (dotWidth + dotGutter)),
      step = 0,
      totalEls = 0,
      koef = 0,
      firstHorLine = 0, firstVertLine = 0, secondHorLine = 0, secondVertLine = 0, thirdHorLine = 0,
      $packman = $(".packman"),
      $firstBlock = $(".fourth-screen__text-block--first"),
      $secondBlock = $(".fourth-screen__text-block--second"),
      $thirdBlock = $(".fourth-screen__text-block--third"),
      $title = $(".fourth-screen__title"),
      wWidth = $(window).width();

    if (wWidth < 1025) return;

    for (var i = 1; i <= dotHorCount; i++) {
      if (i < Math.floor(dotHorCount * 0.33)) {
        $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: 5px"></span>`);

        totalEls++;

        firstHorLine = (dotWidth + dotGutter) * i;

        if (i === 1) {
          $firstBlock.css({
            'left': `${(dotWidth + dotGutter) * i}px`,
            'bottom': '90px'
          })
        }
      }
      if (i === Math.floor(dotHorCount * 0.33)) {
        for (var j = 0; j <= Math.ceil(dotVertCount / 2); j++) {
          if (j === 0) {
            $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: 5px"></span>`);
            totalEls++;
          } else {
            $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: ${(dotHeight + dotGutter) * j}px"></span>`);
            totalEls++;
          }

          firstVertLine = (dotHeight + dotGutter) * j;
        }

        $secondBlock.css({
          'left': `${firstHorLine + 25}px`,
          'bottom': `${firstVertLine + 90}px`
        });
      }
      if (i > Math.floor(dotHorCount * 0.33) && i <= Math.floor(dotHorCount * 0.66)) {
        $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: ${(dotHeight + dotGutter) * (j - 1)}px"></span>`);
        totalEls++;
        secondHorLine = (dotHeight + dotGutter) * i;
      }
      if (i === Math.floor(dotHorCount * 0.66)) {
        for (; j <= dotVertCount; j++) {
          if (j === Math.ceil(dotVertCount / 2)) {
            $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: ${(dotWidth + dotGutter) * (j - 1)}px"></span>`);
            totalEls++;
          } else {
            $container.append(`<span class="line-dot" style="left: ${(dotWidth + dotGutter) * i}px; bottom: ${(dotHeight + dotGutter) * j}px"></span>`);
            totalEls++;
          }

          secondVertLine = (dotHeight + dotGutter) * j;
        }
        $thirdBlock.css({
          'left': `${secondHorLine}px`,
          'bottom': `${secondVertLine + 90}px`
        });
        $title.css("left", `${secondHorLine}px`)
      }
      if (i >= Math.floor(dotHorCount * 0.66)) {
        $container.append(`<span class="line-dot kk" style="left: ${(dotWidth + dotGutter) * i}px; bottom: ${(dotHeight + dotGutter) * (j - 1)}px"></span>`);
        totalEls++;
        thirdHorLine = (dotHeight + dotGutter) * i;
      }
    }


    koef = 1 / totalEls;
    var dots = document.querySelectorAll(".line-dot");
    packmanTimeline.eventCallback('onUpdate', () => {
      step = packmanTimeline.progress();
      var index = step / koef;
      // if (index > dots.length - 1) return;
      index = ~~(index + packmanCounter * step);
      if (packmanWhiteList.indexOf(index) === -1) {
        packmanCounter += (wWidth > 1700) ? 0.2 : (wWidth > 1430) ? 0.15 : 0.12;
        if (dots[index]) {
          dots[index].style.opacity = 0;
        }
        packmanWhiteList.push(index);
      }
    });

    packmanTimeline.eventCallback('onRepeat', () => {
      packmanWhiteList = [];
      packmanCounter = 0;
    });

    packmanTimeline
      .addLabel("start")
      .staggerTo($(".line-dot"), 0, {opacity: 1}, 0.05)
      .to($packman, 5, {x: firstHorLine + 20, ease: Power0.easeNone}, "start")
      .to($packman, 0.2, {rotation: "-90deg", ease: Power0.easeNone})
      .to($packman, 2, {y: -firstVertLine + 5, ease: Power0.easeNone})
      .to($packman, 0.2, {rotation: "0deg", ease: Power0.easeNone})
      .to($packman, 5, {x: secondHorLine - 10, ease: Power0.easeNone})
      .to($packman, 0.2, {rotation: "-90deg", ease: Power0.easeNone})
      .to($packman, 2, {y: -secondVertLine + 7, ease: Power0.easeNone})
      .to($packman, 0.2, {rotation: "0deg", ease: Power0.easeNone})
      .to($packman, 5, {x: thirdHorLine, ease: Power0.easeNone})
      .to($packman, 0.1, {autoAlpha: 0})
      .set($packman, {x: 10, y: 0})
      .to($packman, 0.1, {autoAlpha: 1})
      .staggerTo($(".line-dot"), 0, {opacity: 1}, 0.05)
    ;
  }());

  if (window.navigator.userAgent.indexOf("Edge") > -1 || window.navigator.userAgent.indexOf('Trident/') > -1) {
    $("body").addClass("IE");
  }

  if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf('Chrome') === -1) {
    $("body").addClass("safari");


  }

  (function () {
    if ($(".Ppc, .Seo, .Smm").length > 0) {
      var container = $(".seo-page-progress__project-info"),
        texts = $(".seo-page-progress__project-text"),
        maxH = 0;


      for (let i = 0; i < texts.length; i++) {
        maxH = $(texts).outerHeight() > maxH ? $(texts).outerHeight() : maxH;
      }

      container.height(maxH);
    }
    if ($(".smm-wits-style__inner").length > 0) {

    }
  }());


  objectFitImages('.img-fit');

  $(".header__menu-btn").click(function () {
    var menuTimeline = new TimelineMax({paused: true});

    menuTimeline
      .addLabel("start")
      .fromTo(".mobile-menu", 0.05, {autoAlpha: 0, zIndex: -1}, {autoAlpha: 1, zIndex: 9})
      .fromTo(".mobile-menu__bg", 0.3, {scaleY: 0, transformOrigin: "top center"}, {scaleY: 1}, "start")
      .staggerFromTo(".header__link--nav", 0.2, {autoAlpha: 0, y: -10}, {
        autoAlpha: 1,
        y: 0
      }, 0.1, "start+=0.2")
      .staggerFromTo(".header__link--tel", 0.3, {autoAlpha: 0, y: -10}, {
        autoAlpha: 1,
        y: 0
      }, 0.1, "start+=0.3")
      .fromTo(".dropdown--lang", 0.2, {autoAlpha: 0}, {autoAlpha: 1}, "start+=0.4");

    if ($(this).hasClass("active")) {
      $("body").removeClass("no-scroll");
      $(".header").addClass("scrolled dark");
      $(this).removeClass("active");
      menuTimeline.progress(1, false);
      menuTimeline.timeScale(3);
      menuTimeline.reverse();

      //TweenMax.set(".mobile-menu",  {autoAlpha: 0, zIndex: -1});
    } else {
      $("body").addClass("no-scroll");
      $(".header").removeClass("scrolled dark");
      $(this).addClass("active");
      menuTimeline.play(0)
    }
  });

  (function () {


    $(".lucky-numbers__number span").mouseover(function () {
      var parentt = $(this).parents(".lucky-numbers__number"),
        hand = $(".we-believe-section__rotate-hand");
      parentt.addClass("active");
      hand.addClass("rotated");
    });

    $(".lucky-numbers__number").on('mouseleave', function () {
      var parentt = $(this),
        hand = $(".we-believe-section__rotate-hand");
      parentt.removeClass("active");
      hand.removeClass("rotated");
    });

    $('body').on('touchend', function (e) {
      if ($(e.target).parents(".lucky-numbers__number").length > 0) return;
      $(".we-believe-section__rotate-hand").removeClass("rotated");
      $(".lucky-numbers__number.active").removeClass("active");

    });
  }());


  $(".mobile-menu .dropdown__toggle").click(function () {
    $(this).parents(".dropdown").toggleClass("active");
  });

  $(".dropdown__toggle").click(function (e) {
    if ($(e.target).parents(".mobile-menu").length > 0) return;
    $(this).parents(".dropdown").toggleClass("active");
  });

  $("body").click(function (e) {


    if (!($(e.target).parents(".dropdown.active").length > 0)) {
      $(".dropdown").removeClass("active");
    } else if ($(e.target).hasClass(".dropdown__toggle")) {
      $(".dropdown").removeClass("active");
    }
  });

  $(".footer__services-toggle").click(function (e) {
    var cities = $(this).attr("data-cities"),
      $container = $("#services-cities");
    if ($(this).hasClass("active")) {
      $container.empty();
      $(this).removeClass("active");
    } else {
      $container.text(cities);
      $(".footer__services-toggle").each(function () {
        $(this).removeClass("active");
      });
      $(this).addClass("active");
    }
  });

  const players = Plyr.setup('.js-player', {
    controls: ['play-large', 'mute'],
    youtube: {showinfo: 0}
  });

  if (!homepage) {

    if ($(".bg-secondary").length === 0) {
      $("#header").addClass("dark");
      if ($(window).scrollTop() > 50) {
        $("#header").addClass("scrolled ");
      } else {
        $("#header").removeClass("scrolled ");
      }

      $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
          $("#header").addClass("scrolled ");
        } else {
          $("#header").removeClass("scrolled ");
        }
      });
    } else {
      if ($(window).scrollTop() > 50) {
        $("#header").addClass("scrolled dark");
      } else {
        $("#header").removeClass("scrolled dark");
      }

      $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
          $("#header").addClass("scrolled dark");
        } else {
          $("#header").removeClass("scrolled dark");
        }
      });
    }


  }

  $('a[href*="#"]').on('click', function (el) {
    el.preventDefault();

    $('html, body').animate({
      scrollTop: $($(this).attr('href')).offset().top - $("header").outerHeight()
    }, 500, 'linear');
  });
  $(".hidden-section__toggle").click(function () {
    var expanded = $(this).attr('aria-expanded'),
        more = $(this).data('more'),
        hide = $(this).data('hide');
    if ('false' === expanded) {
        $(this).attr('aria-expanded', 'true');
        $(this).text(hide);
    } else {
        $(this).attr('aria-expanded', 'false');
        $(this).text(more);
    }
    $(".hidden-section__copy-text").toggleClass("active");
  });

  $(".open-side-form").click(function () {
    $(".side-form").addClass("active");
    $("body").addClass("no-scroll");
  });

  $(".side-form__bg, .side-form__close-btn").click(function (e) {
    $(".side-form").removeClass("active");
    $("body").removeClass("no-scroll");
  });


  if (homepage) {

    if ($(window).width() > 768) {
      var nextSlide = 0,
        resetAnim = function () {
          overlayTimeline.restart().pause();
        },
        overlayTimeline = new TimelineMax({paused: true, onComplete: resetAnim});

      const roundish = document.getElementById("homepage-roundish"),
        paths = roundish.querySelectorAll("path");

      var roundishTimeline = new TimelineMax({paused: true}),

        firstScreenTimeline = new TimelineMax({paused: true}),
        firstScreenTitleOneText = new SplitText($(".first-screen .h2")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        firstScreenTitleOneWords = firstScreenTitleOneText.words,
        firstScreenTitleTwoText = new SplitText($(".first-screen .h2")[1], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        firstScreenTitleTwoWords = firstScreenTitleTwoText.words,
        secondScreenTimeline = new TimelineMax({paused: true}),
        secondScreenTitleText = new SplitText($(".second-screen__title")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        secondScreenTitleWords = secondScreenTitleText.words,
        secondScreenSubtitleText = new SplitText($(".second-screen__subtitle")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        secondScreenSubtitleWords = secondScreenSubtitleText.words,
        secondScreenChangeText = $(".second-screen__change-text-wrap")[0],
        secondScreenMainText = $(".second-screen__main-text")[0],
        secondScreenSlideText = $(".second-screen__sliding-text")[0],
        secondScreenSlideOverlay = $(".second-screen__change-img-overlay")[0],
        thirdScreenTimeline = new TimelineMax({paused: true}),
        thirdScreenTitleText = new SplitText($(".third-screen__content .h2")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        thirdScreenTitleWords = thirdScreenTitleText.words,
        thirdScreenSubtitleText = new SplitText($(".third-screen__subtitle")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        thirdScreenSubtitleWords = thirdScreenSubtitleText.words,
        thirdScreenStatisticTitle = new SplitText($(".third-screen__statistic-title")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        thirdScreenStatisticTitleWords = thirdScreenStatisticTitle.words,
        thirdScreenStatisticText = new SplitText($(".third-screen__accent"), {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        thirdScreenStatisticWords = thirdScreenStatisticText.words,
        thirdScreenLeftImageOverlay = $(".third-screen__img-overlay")[0],
        thirdScreenWhiteImages = $(".third-screen__white img"),
        thirdScreenWhiteUnderlay = $(".third-screen__white-underlay")[0],
        thirdScreenStatistics = $('.third-screen__statistic'),
        fourthScreenTimeline = new TimelineMax({paused: true}),
        fourthScreenBlocks = $(".fourth-screen__text-block"),
        fourthScreenTitleText = new SplitText($(".fourth-screen__title")[0], {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        fourthScreenTitleWords = fourthScreenTitleText.words,
        fifthScreenTimeline = new TimelineMax({paused: true}),
        fifthScreenFirstTitle = $(".fifth-screen__info .h2")[0],
        fifthScreenFirstTitleText = new SplitText(fifthScreenFirstTitle, {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        fifthScreenFirstTitleWords = fifthScreenFirstTitleText.words,
        fifthScreenSecondTitle = $(".fifth-screen__info .h2")[1],
        fifthScreenSecondTitleText = new SplitText(fifthScreenSecondTitle, {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        fifthScreenSecondTitleWords = fifthScreenSecondTitleText.words,
        fifthScreenFirstText = $(".fifth-screen__info p")[0],
        fifthScreenFirstTextText = new SplitText(fifthScreenFirstText, {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        fifthScreenFirstTextWords = fifthScreenFirstTextText.words,
        fifthScreenSecondText = $(".fifth-screen__info p")[1],
        fifthScreenSecondTextText = new SplitText(fifthScreenSecondText, {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        fifthScreenSecondTextWords = fifthScreenSecondTextText.words,
        fifthScreenAccentText = $(".fifth-screen__info p.text-primary")[0],
        fifthScreenAccentTextText = new SplitText(fifthScreenAccentText, {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        fifthScreenAccentTextWords = fifthScreenAccentTextText.words,
        fifthScreenScalable = $(".scalable-sign__bounder")[0],
        fifthScreenScalableText = $("#fit-text"),
        fifthScreenScalableTextText = new SplitText(fifthScreenScalableText, {
          type: "chars, words",
          wordsClass: "d-flex flex-wrap"
        }),
        fifthScreenScalableTextChars = fifthScreenScalableTextText.chars,
        fifthScreenScalableHint = $(".fifth-screen__hint")[0],
        sixthScreenTimeline = new TimelineMax({paused: true}),
        sixthScreenTitle = $(".sixth-screen__info .h2")[0],
        sixthScreenTitleText = new SplitText(sixthScreenTitle, {
          type: "words, lines",
          linesClass: "overflow-hidden lh-46"
        }),
        sixthScreenTitleWords = sixthScreenTitleText.words,
        sixthScreenText = $(".sixth-screen__info p")[0],
        sixthScreenTextText = new SplitText(sixthScreenText, {
          type: "words, lines",
          linesClass: "overflow-hidden"
        }),
        sixthScreenTextWords = sixthScreenTextText.words,
        sixthScreenBtnOverlay = $(".sixth-screen__btn-wrap-overlay")[0],
        sixthScreenCanvas = $(".sixth-screen__repellent")[0]
      ;

      sixthScreenTimeline
        .addLabel("start")
        .fromTo(sixthScreenCanvas, 1, {opacity: 0}, {opacity: 1}, "start")
        .staggerFromTo(sixthScreenTitleWords, 1, {y: 70}, {y: 0}, 0, "start")
        .staggerFromTo(sixthScreenTextWords, 1, {y: 30}, {y: 0}, 0, "start+=1")
        .fromTo(sixthScreenBtnOverlay, 1, {scaleX: 1}, {scaleX: 0}, "start+=1");

      fifthScreenTimeline
        .addLabel("start")
        .staggerFromTo(fifthScreenFirstTitleWords, 1, {y: 70}, {y: 0}, 0, "start")
        .staggerFromTo(fifthScreenSecondTitleWords, 1, {y: 70}, {y: 0}, 0, "start+=0.5")
        .staggerFromTo(fifthScreenFirstTextWords, 1, {y: 30}, {y: 0}, 0, "start+=1")
        .staggerFromTo(fifthScreenSecondTextWords, 1, {y: 30}, {y: 0}, 0, "start+=1")
        .staggerFromTo(fifthScreenAccentTextWords, 1, {y: 30}, {y: 0}, 0, "start+=1.5")
        .fromTo(fifthScreenScalable, 1, {autoAlpha: 0}, {autoAlpha: 1}, "start+=0.5")
        .staggerFromTo(fifthScreenScalableTextChars, 0.4, {autoAlpha: 0}, {autoAlpha: 1}, 0.1, "start+=1")
        .fromTo(fifthScreenScalableHint, 1, {autoAlpha: 0}, {autoAlpha: 1}, "start+=2");


      fourthScreenTimeline
        .addLabel("start")
        .staggerFromTo(fourthScreenTitleWords, 1, {y: 30}, {y: 0}, 0, "start")
        .staggerFromTo(fourthScreenBlocks, 1, {autoAlpha: 0, y: 20}, {autoAlpha: 1, y: 0}, -0.5, "start");


      thirdScreenTimeline
        .addLabel("start")
        .fromTo(thirdScreenLeftImageOverlay, 1, {scaleY: 1}, {scaleY: 0}, "start")
        .staggerFromTo(thirdScreenTitleWords, 1, {y: 30}, {y: 0}, 0, "start")
        .staggerFromTo(thirdScreenSubtitleWords, 1, {y: 30}, {y: 0}, 0, "start+=0.5")
        .fromTo(thirdScreenWhiteUnderlay, 1, {
          scaleX: 0,
          transformOrigin: "top right",
          ease: Power3.easeOut
        }, {scaleX: 1}, "start+=1")
        .staggerFromTo(thirdScreenWhiteImages, 0.5, {autoAlpha: 0}, {autoAlpha: 1}, 0.4, "start+=1.5")
        .staggerFromTo(thirdScreenStatisticTitleWords, 1, {y: 30}, {y: 0}, 0, "start+=2")
        .staggerFromTo(thirdScreenStatistics, 1, {autoAlpha: 0}, {autoAlpha: 1}, 0.4, "start+=2.5")
        .staggerFromTo(thirdScreenStatisticWords, 1, {y: 30}, {y: 0}, 0, "start+=3.5")
      ;


      secondScreenTimeline
        .addLabel("start")
        .fromTo(secondScreenMainText, 0.8, {autoAlpha: 0, y: -10}, {autoAlpha: 1, y: 0}, "start")
        .to(secondScreenSlideText, 0, {className: "-=slided"}, "start+=0.6")
        .staggerFromTo(secondScreenTitleWords, 1, {y: 70}, {y: 0}, 0, "start+=0.4")
        .staggerFromTo(secondScreenSubtitleWords, 1, {y: 30}, {y: 0}, 0, "start+=0.5")
        .fromTo(secondScreenChangeText, 1, {y: 10, autoAlpha: 0}, {
          y: 0,
          autoAlpha: 1
        }, 0.1, "start+=0.7")
        .fromTo(secondScreenSlideOverlay, 1, {scaleY: 1}, {scaleY: 0}, "start+=1");


      firstScreenTimeline
        .addLabel("start")
        .staggerFromTo(firstScreenTitleOneWords, 1, {y: 70}, {y: 0}, 0, "start")
        .staggerFromTo(firstScreenTitleTwoWords, 1, {y: 70}, {y: 0}, 0, "start+=0.3")
        .fromTo($(".first-screen__text")[0], 1, {y: 10, autoAlpha: 0}, {y: 0, autoAlpha: 1}, "start+=0.7")
        .fromTo($(".first-screen__text")[1], 1, {y: 10, autoAlpha: 0}, {y: 0, autoAlpha: 1}, "start+=0.9")
        .fromTo($(".first-screen__message .icon-info")[0], 0.5, {
          scale: 0,
          transformOrigin: "50% 50%"
        }, {scale: 1}, "start+=1.3")
        .fromTo($(".first-screen__message .info-label__overlay")[0], 1, {scaleX: 1}, {scaleX: 0}, "start+=1.4")
      ;
      if (navigator.userAgent.indexOf("Safari") > -1 && navigator.userAgent.indexOf('Chrome') === -1) {

        roundishTimeline.fromTo(roundish, 0.9,
          {
            opacity: 0
          }, {
            opacity: 1
          }, "+=0.2");
      } else {
        roundishTimeline.staggerFromTo(paths, 0.9,
          {
            opacity: 0,
            scale: 0.9,
            transformOrigin: "50% 50%"
          }, {
            opacity: 1,
            scale: 1
          }, 0.02);

      }

      window.secondScreenCounter = 0;
      window.allowScroll = true;
      let timer;

      new fullpage('#homepage', {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        anchors: ['section0', 'section1', 'section2', 'section3', 'section4', 'section5', 'section6', 'section7', 'section8'],
        scrollingSpeed: 1700,
        onLeave: function (origin, destination, direction) {

          /*if (direction === 'up' && window.secondScreenCounter === 0 && destination.index === 1) {
            /!*fullpage_api.moveSectionUp();
            setTimeout(() => {
              window.allowScroll = true;
            }, 1000);*!/
          } else if (direction === 'up' && window.secondScreenCounter === 1 && destination.index === 1) {
            $(".second-screen__prev-btn").trigger("click");
            //window.allowScroll = false;
            window.secondScreenCounter = 0;
            return false;
          } else if (direction === 'down' && window.secondScreenCounter === 0 && destination.index === 3) {
            $(".second-screen__next-btn").trigger("click");
            //window.allowScroll = false;
            window.secondScreenCounter = 1;
            return false;
          } else if (direction === 'down' && window.secondScreenCounter === 1 && destination.index === 3) {
            /!*fullpage_api.moveSectionDown();
            setTimeout(() => {
              window.allowScroll = true;
            }, 1000);*!/
          }*/

          if (destination.index !== 4) {
            packmanTimeline.restart();
            packmanTimeline.pause();
            packmanWhiteList = [];
            packmanCounter = 0;
          }

          if (destination.index > 0) {
            $("#header").addClass("scrolled dark");
            TweenMax.to($(".homepage-indicator"), 0.5, {autoAlpha: 1});

          } else {
            $("#header").removeClass("scrolled dark");
          }


          if (destination.index === 3 && !allowScroll) {
            if (direction === 'down') {
              window.allowScroll = false;
              if (secondScreenCounter === 0) {
                secondScreenCounter = 1;
                $(".second-screen__next-btn").trigger("click");
                if (timer) {
                  clearInterval(timer)
                }
                timer = setTimeout(() => {
                  window.allowScroll = true;
                }, 1500);
              }
            }
          }

          if (destination.index === 1 && !allowScroll) {
            if (direction === 'up') {

              window.allowScroll = false;
              if (secondScreenCounter === 1) {

                secondScreenCounter = 0;
                $(".second-screen__prev-btn").trigger("click");

                if (timer) {
                  clearInterval(timer)
                }
                timer = setTimeout(() => {
                  window.allowScroll = true;
                }, 1500);
              }

            }
          }
          if (!allowScroll) {
            return false;
          }


          if (destination.index < 7) {
            if (direction === 'down') {
              overlayTimeline.play();
            } else {
              overlayTimeline.reverse(1.7);
            }
          }

          if (destination.index === 7 && direction === 'down') {
            overlayTimeline.play();
          }
          /*if (destination.index > 7) {
              $("#header").addClass("scrolled-to-footer");
          }else{
              $("#header").removeClass("scrolled-to-footer");
          }*/
        },
        afterLoad(origin, destination, direction) {

          if (destination.index === 2) {
            window.allowScroll = false;
          }

          if (destination.index === 1) {
            roundishTimeline.play();
            firstScreenTimeline.play();

            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "103px"});
          }
          if (destination.index === 2) {
            secondScreenTimeline.play();
            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "86px"});
          }
          if (destination.index === 3) {
            thirdScreenTimeline.play();
            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "69px"});
          }
          if (destination.index === 4) {
            fourthScreenTimeline.play();
            packmanTimeline.play();
            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "54px"});
          }
          if (destination.index === 5) {
            fifthScreenTimeline.play();
            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "35px"});
          }
          if (destination.index === 6) {
            sixthScreenTimeline.play();
            TweenMax.to($("#indicator-red-path"), 0.4, {strokeDashoffset: "18px"});
          }
          if (destination.index === 7) {
            TweenMax.staggerTo($(".homepage-indicator__red-svg, .homepage-indicator__grey-svg"), 0.2, {autoAlpha: 0}, 0);

            TweenMax.to($(".homepage-indicator__top-svg"), 0.2, {autoAlpha: 1});

            $(".homepage-indicator").click(function () {
              fullpage_api.moveTo('section1', 0);
            })
          }
          if (destination.index < 7) {
            TweenMax.staggerTo($(".homepage-indicator__red-svg, .homepage-indicator__grey-svg"), 0.2, {autoAlpha: 1}, 0);

            TweenMax.to($(".homepage-indicator__top-svg"), 0.2, {autoAlpha: 0});
            $(".homepage-indicator").click(function () {
              return false
            })
          }
          if (destination.index > 7 || destination.index === 0) {
            TweenMax.staggerTo($(".homepage-indicator"), 0.2, {autoAlpha: 0}, 0);
            $(".homepage-indicator").click(function () {
              return false
            })
          }

        }
      });

      overlayTimeline
        .addLabel("start")
        .to(".homepage-overlay__red", 0.5, {scaleY: 1, transformOrigin: "bottom left"}, "start")
        .to(".homepage-overlay__red", 0.5, {scaleY: 0, transformOrigin: "top left"}, "start+=0.6")
        .to(".homepage-overlay__dark", 0.5, {scaleY: 1, transformOrigin: "bottom left"}, "start+=0.6")
        .to(".homepage-overlay__dark", 0.5, {scaleY: 0, transformOrigin: "top left"}, "start+=1.2");

      $(".button-down").click(function () {
        fullpage_api.moveTo('section1', 0);
      });

    } else {

      $(".button-down").click(function () {
        $('html,body').animate({scrollTop: window.innerHeight - 58}, 'slow');
      });

      if ($(window).scrollTop() > 100) {
        $("#header").addClass("scrolled dark");
      } else {
        $("#header").removeClass("scrolled dark");
      }

      $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
          $("#header").addClass("scrolled dark");
        } else {
          $("#header").removeClass("scrolled dark");
        }
      });
    }

    (function () {
      $('.scalable-sign').each(function () {
        var drag = $(this),
          handlers = $(".scalable-sign__resize-handle"),
          max = {
            x: $("#scalable-sign-bounder").width(),
            y: $("#scalable-sign-bounder").height() * 0.8
          };

        /*Draggable.create(drag);*/

        for (let i = 0; i < handlers.length; i++) {
          Draggable.create(handlers[i], {
            type: "top,left",
            bounds: {
              minX: 200,
              minY: 100,
              maxY: max.y,
              maxX: max.x
            },
            onPress: function (e) {
              e.stopPropagation(); // cancel drag
              TweenMax.to(".fifth-screen__hint", 0.2, {autoAlpha: 0});
            },
            onRelease: function (e) {
              TweenMax.to(".fifth-screen__hint", 0.2, {autoAlpha: 1});

            },
            onDrag: function (e) {
              TweenMax.set(drag, {width: this.x, height: this.y});

              TweenMax.set("#fit-text", {
                scale:
                  window.outerWidth >= 1360 ?
                    (this.x / max.x + this.y / max.y) / 1.5 :
                    window.outerWidth > 1024 ?
                      (this.x / max.x + this.y / max.y) / 1.7 :
                      window.outerWidth > 370 ?
                        ((this.x / max.x + this.y / max.y) / 2) :
                        ((this.x / max.x + this.y / max.y) / 2.5),
                // scale: ((this.x / max.x + this.y / max.y) / 2),
                transformOrigin: "0 0"
              });
            }
          });
        }
      });
    }());

    (function () {
      var imageOne = $(".second-screen__img--first "),
        imageTwo = $(".second-screen__img--second "),
        textOne = $(".second-screen__change-text--first"),
        textOneSplit = new SplitText(textOne, {type: "lines"}),
        textOneLines = textOneSplit.lines,
        textTwo = $(".second-screen__change-text--second"),
        textTwoSplit = new SplitText(textTwo, {type: "lines"}),
        textTwoLines = textTwoSplit.lines,
        titleOne = $(".second-screen__sliding-text--first"),
        titleTwo = $(".second-screen__sliding-text--second"),
        counter = 0,
        textWrapHeight = $(".second-screen__change-text--first").height() >
        $(".second-screen__change-text--second").height() ? $(".second-screen__change-text--first").height() : $(".second-screen__change-text--second").height();

      $(".second-screen__change-text-wrap").each(function () {
        $(this).height(textWrapHeight);
      });

      $(".second-screen__prev-btn").click(function () {
        const timeline = new TimelineMax();

        timeline.addLabel("start")
          .to(imageOne, 1, {yPercent: "0%"}, "start")
          .to(imageTwo, 1, {yPercent: "0%"}, "start")
          .to(textOne, 1, {yPercent: "0%"}, "start")
          .to(textTwo, 1, {yPercent: "0%"}, "start")
          .to(titleOne, 1, {className: "+=active"}, "start")
          .to(titleTwo, 1, {className: "-=active"}, "start")
          .staggerTo(textOneLines, 0.1, {skewY: "-2deg", transformOrigin: "-100% 0"}, 0, "start")
          .staggerTo(textTwoLines, 0.1, {skewY: "-2deg", transformOrigin: "-100% 0"}, 0, "start")
          .staggerTo(textOneLines, 0.3, {skewY: "0"}, 0, "start+=0.8")
          .staggerTo(textTwoLines, 0.3, {skewY: "0"}, 0, "start+=0.8");


      });

      $(".second-screen__next-btn").click(function () {
        const timeline = new TimelineMax();

        timeline
          .addLabel("start")
          .to(imageOne, 1, {yPercent: "-100%"}, "start")
          .to(imageTwo, 1, {yPercent: "-100%"}, "start")
          .to(textOne, 1, {yPercent: "-100%"}, "start")
          .to(textTwo, 1, {yPercent: "-100%"}, "start")
          .to(titleOne, 1, {className: "-=active"}, "start")
          .to(titleTwo, 1, {className: "+=active"}, "start")
          .staggerTo(textOneLines, 0.1, {skewY: "2deg", transformOrigin: "100% 0"}, 0, "start")
          .staggerTo(textTwoLines, 0.1, {skewY: "2deg", transformOrigin: "100% 0"}, 0, "start")
          .staggerTo(textOneLines, 0.3, {skewY: "0"}, 0, "start+=0.8")
          .staggerTo(textTwoLines, 0.3, {skewY: "0"}, 0, "start+=0.8");

      });
    }());
  }

  $(".contact-form__send-file-btn").click(function () {
    $(this).siblings("input").trigger("click");
  });

});

(() => {

  $("document").ready(function () {
    $(".smm-wits-style__inner").height($(".smm-wits-style__content.active").outerHeight());
  });

  $(".smm-wits-style__tab").click(function (e) {
    if ($(this).hasClass("active")) return;

    var target = "#" + $(this).attr("data-tab-target");

    $(".smm-wits-style__tab").each(function () {
      $(this).removeClass("active");
    });
    $(".smm-wits-style__content").each(function () {
      $(this).removeClass("active");
    });
    $(this).addClass("active");
    $(".smm-wits-style__inner").height($(target).outerHeight());
    $(target).addClass("active");
  });


})();


$("document").ready(function () {
  $(".spy-eye__iris").each(function () {
    var eye = $(this),
      pupil = $(this).find(".spy-eye__pupil");

    $(window).mousemove(function (event) {
      var x = (eye.offset().left) + (eye.width() / 2),
        y = (eye.offset().top) + (eye.height() / 2),
        rad = Math.atan2(event.pageX - x, event.pageY - y),
        rot = (rad * (180 / Math.PI) * -1) + 120,
        px = (pupil.offset().left) + (pupil.width() / 2),
        py = (pupil.offset().top) + (pupil.height() / 2),
        prad = Math.atan2(event.pageX - px, event.pageY - py),
        prot = (prad * (180 / Math.PI) * -1) + 120;

      if ($(event.target).is(eye) || $(event.target).is(pupil)) return;

      pupil.css({
        '-webkit-transform': 'rotate(' + (-prot) + 'deg)',
        '-moz-transform': 'rotate(' + (-prot) + 'deg)',
        '-ms-transform': 'rotate(' + (-prot) + 'deg)',
        'transform': 'rotate(' + (-prot) + 'deg)'
      });

      eye.css({
        '-webkit-transform': 'rotate(' + rot + 'deg)',
        '-moz-transform': 'rotate(' + rot + 'deg)',
        '-ms-transform': 'rotate(' + rot + 'deg)',
        'transform': 'rotate(' + rot + 'deg)'
      });

      //TweenMax.to(eye, 0.2, {rotation: rot})
    });
  });

  var aboutShowContent = false;
  $(".about-start__show-content button").click(function () {
    var $wrap = $(this).parents(".about-start__content-wrap"),
      allHeight = $wrap.outerHeight() - 24,
      paragraph = $wrap.find(".about-start__content").find("p"),
      showContentHeight = $wrap.find(".about-start__show-content").outerHeight(),
      titleHeight = $wrap.find("h2").outerHeight(),
      availableHeight = 0;

    if ($(window).width() > 768) {
      availableHeight = allHeight - showContentHeight - remToPix(5) - titleHeight - remToPix(4);

      if (aboutShowContent) {
        paragraph.scrollTop(0);
        TweenMax.to(paragraph, 0.5, {maxHeight: 180, overflow: "hidden"});
      } else {
        TweenMax.to(paragraph, 0.5, {maxHeight: availableHeight, overflow: "auto"});
      }
      aboutShowContent = !aboutShowContent;
    }
  });

  /*$(".viber-link").each(function () {
    var link = $(this).attr('href'),
      newLink;

    newLink = [].filter.call(link, (e,i) => {
      return e !== ' ' && e !== '(' && e !== ')';
    });

    $(this).attr('href', newLink.join(''))

  });*/
});

(function () {

  var $slider = $("#flowSlider");
  if (!$slider.length > 0) return false;

  var windowWidth = $(window).width(),
    initHeight = 0,
    topLine = {
      els: [],
      height: 0,
      width: 0
    },
    bottomLine = {
      els: [],
      height: 0,
      width: 0
    },
    elements = $slider.find(".flow-slider__item"),
    slip = 0;

  for (let i = 0; i < elements.length; i++) {
    if (i < Math.round(elements.length / 2)) {
      topLine.els.push(elements[i]);
      topLine.height = $(elements[i]).outerHeight() > topLine.height ? $(elements[i]).outerHeight() : topLine.height;

      TweenMax.set(elements[i], {top: 0, left: topLine.width});
      topLine.width += $(elements[i]).outerWidth() + 20;

      /*if (checkIfOut(elements[i], windowWidth, 1)) {
          TweenMax.set(elements[i], {top: 0, left: -(topLine.width)});
      }*/

    } else {
      bottomLine.els.push(elements[i]);
      bottomLine.height = $(elements[i]).outerHeight() > bottomLine.height ? $(elements[i]).outerHeight() : bottomLine.height;

      TweenMax.set(elements[i], {top: topLine.height + 20, right: bottomLine.width});
      bottomLine.width += $(elements[i]).outerWidth() + 20;
    }
  }

  $slider.height(topLine.height + bottomLine.height + 20);

  var translateSlider = 0,
    slipTopLeft = 0,
    slipTopRight = windowWidth,
    slipBottomLeft = 0,
    slipBottomRight = 0;

  $slider.on('DOMMouseScroll mousewheel', function (ev) {
    var $this = $(this),
      scrollTop = this.scrollTop,
      scrollHeight = this.scrollHeight,
      height = $this.height(),
      delta = (ev.type == 'DOMMouseScroll' ?
        ev.originalEvent.detail * -40 :
        ev.originalEvent.wheelDelta),
      up = delta > 0;

    if (!up) {
      translateSlider += Math.abs(delta / 4);

      TweenMax.staggerTo(topLine.els, 0.9, {
        x: translateSlider,
        skewX: Math.min(Math.abs(delta) / 10, 12),
        onUpdate: updateTopElLeft,
        onUpdateParams: ["{self}"],
        ease: Power2.easeOut
      }, 0);


      TweenMax.staggerTo(bottomLine.els, 1, {
        x: -(translateSlider),
        skewX: -(Math.min(Math.abs(delta) / 10, 12)),
        onUpdate: updateBottomElRight,
        onUpdateParams: ["{self}"],
        ease: Power2.easeOut
      }, 0);
    } else {
      setTimeout(function () {
        TweenMax.staggerTo(bottomLine.els, 0.4, {
          skewX: 0
        }, 0);
        TweenMax.staggerTo(topLine.els, 0.4, {
          skewX: 0
        }, 0);
      }, 100);
    }


    var prevent = function () {
      ev.stopPropagation();
      ev.preventDefault();
      ev.returnValue = false;
      return false;
    };


    if (!up && -delta > scrollHeight - height - scrollTop) {
      // Scrolling down, but this will take us past the bottom.
      $this.scrollTop(scrollHeight);
      return prevent();
    } else if (up && delta > scrollTop) {
      // Scrolling up, but this will take us past the top.
      $this.scrollTop(0);
      return prevent();
    }
  });

  $slider.on("mouseenter", function () {
    translateSlider += 1200;

    TweenMax.staggerTo(topLine.els, 0.9, {
      x: translateSlider,
      skewX: 20,
      onUpdate: updateTopElLeft,
      onUpdateParams: ["{self}"],
      ease: Power2.easeOut
    }, 0);


    TweenMax.staggerTo(bottomLine.els, 1, {
      x: -(translateSlider),
      skewX: -20,
      onUpdate: updateBottomElRight,
      onUpdateParams: ["{self}"],
      ease: Power2.easeOut
    }, 0);

    setTimeout(function () {
      TweenMax.staggerTo(bottomLine.els, 0.4, {
        skewX: 0
      }, 0);
      TweenMax.staggerTo(topLine.els, 0.4, {
        skewX: 0
      }, 0);
    }, 100);
  });

  if (windowWidth < 1023) {
    $slider.on("click", function () {
      translateSlider += 1200;

      TweenMax.staggerTo(topLine.els, 0.9, {
        x: translateSlider,
        skewX: 20,
        onUpdate: updateTopElLeft,
        onUpdateParams: ["{self}"],
        ease: Power2.easeOut
      }, 0);


      TweenMax.staggerTo(bottomLine.els, 1, {
        x: -(translateSlider),
        skewX: -20,
        onUpdate: updateBottomElRight,
        onUpdateParams: ["{self}"],
        ease: Power2.easeOut
      }, 0);

      setTimeout(function () {
        TweenMax.staggerTo(bottomLine.els, 0.4, {
          skewX: 0
        }, 0);
        TweenMax.staggerTo(topLine.els, 0.4, {
          skewX: 0
        }, 0);
      }, 100);
    });
  }

  $slider.on("mouseleave click", function () {
    setTimeout(function () {
      TweenMax.staggerTo(bottomLine.els, 0.4, {
        skewX: 0
      }, 0);
      TweenMax.staggerTo(topLine.els, 0.4, {
        skewX: 0
      }, 0);
    }, 100);
  });

  function updateTopElLeft(event) {
    var elLeft = event.target.getBoundingClientRect().left,
      elWidth = event.target.offsetWidth;

    if (elLeft > windowWidth) {
      slipTopLeft += elWidth + 20;
      TweenMax.set(event.target, {left: -(slipTopLeft)})
    }
  }

  function updateBottomElRight(event) {
    var elWidth = event.target.offsetWidth,
      elRight = windowWidth - event.target.getBoundingClientRect().right;

    if (elRight > windowWidth) {
      slipBottomRight += elWidth + 20;
      TweenMax.set(event.target, {right: -(slipBottomRight)})
    }
  }
}());

$(".step-slider-section").each(function () {
  var counter = 0,
    inner = $(this).find(".step-slider__inner"),
    texts = $(this).find(".step-slider__info").find("p"),
    items = $(this).find(".step-slider-item"),
    prevBtn = $(this).find(".step-slider__control--prev"),
    nextBtn = $(this).find(".step-slider__control--next"),
    slip = 0;

  prevBtn.addClass("active");

  for (let i = 0; i < items.length; i++) {
    $(items[i]).click(function () {
      $(texts[counter]).removeClass("active");
      $(items[counter]).removeClass("active");
      prevBtn.removeClass("active");
      nextBtn.removeClass("active");

      counter = i;
      slip = 0;

      if (counter === (items.length - 1)) {
        nextBtn.addClass("active");
      } else if (counter === 0) {
        prevBtn.addClass("active");
      }

      for (let j = 0; j < i; j++) {
        slip += $(items[j]).outerWidth();
      }

      $(texts[counter]).addClass("active");
      $(items[counter]).addClass("active");
      TweenMax.to(inner, 0.6, {x: -(slip)});
    });
  }

  nextBtn.click(function () {
    prevBtn.removeClass("active");
    if (counter === (items.length - 1)) {
      return;
    }

    counter++;
    slip += $(items[counter - 1]).outerWidth();

    $(texts[counter - 1]).removeClass("active");
    $(texts[counter]).addClass("active");
    $(items[counter - 1]).removeClass("active");
    $(items[counter]).addClass("active");

    TweenMax.to(inner, 0.6, {x: -(slip)});
    if (counter === (items.length - 1)) {
      nextBtn.addClass("active");
    } else {
      nextBtn.removeClass("active");
    }

  });

  prevBtn.click(function () {
    nextBtn.removeClass("active");
    if (counter === 0) {
      return;
    }
    counter--;
    if (counter === 0) {
      prevBtn.addClass("active");
      slip = 0;
    } else {
      prevBtn.removeClass("active");
      slip -= $(items[counter - 1]).outerWidth();
    }
    $(texts[counter + 1]).removeClass("active");
    $(texts[counter]).addClass("active");
    $(items[counter + 1]).removeClass("active");
    $(items[counter]).addClass("active");
    TweenMax.to(inner, 0.6, {x: -(slip)});
  });
});

$(".seo-page-progress").each(function () {
  var backgrounds = $(this).find(".seo-page-progress__bg").find("img"),
    squares = $(this).find(".seo-page-progress__square-inner"),
    infos = $(this).find(".seo-page-progress__project-text"),
    prevBtn = $(this).find(".progress-slider-control--left"),
    nextBtn = $(this).find(".progress-slider-control--right"),
    counter = 0;

  TweenMax.set(backgrounds[counter], {autoAlpha: 0.3});
  TweenMax.set(squares[counter], {autoAlpha: 1});
  TweenMax.set(infos[counter], {autoAlpha: 1});

  nextBtn.click(function () {
    prevBtn.removeClass("active");
    /*if (counter === (backgrounds.length - 1)) {
      return;
    }*/
    TweenMax.to(backgrounds[counter], 0.7, {autoAlpha: 0});
    TweenMax.to(squares[counter], 0.7, {autoAlpha: 0, y: -20, transformOrigin: "0 0", skewY: "3deg"});
    TweenMax.to(infos[counter], 0.7, {autoAlpha: 0, y: -20, transformOrigin: "0 0", skewY: "3deg"});

    counter++;
    if(counter === backgrounds.length) counter = 0;

    TweenMax.fromTo(backgrounds[counter], 0.7, {autoAlpha: 0}, {autoAlpha: 0.3});
    TweenMax.fromTo(squares[counter], 0.7, {autoAlpha: 0, y: 20, transformOrigin: "0 0", skewY: "3deg"},
      {autoAlpha: 1, y: 0, transformOrigin: "0 0", skewY: "0"});
    TweenMax.fromTo(infos[counter], 0.7, {
      autoAlpha: 0,
      y: 20,
      transformOrigin: "0 0",
      skewY: "3deg"
    }, {autoAlpha: 1, y: 0, transformOrigin: "0 0", skewY: "0"});

    //counter++;
  });

  prevBtn.click(function () {
    nextBtn.removeClass("active");
    /*if (counter === 0) {
      return;
    }*/
    TweenMax.to(backgrounds[counter], 0.7, {autoAlpha: 0});
    TweenMax.to(squares[counter], 0.7, {autoAlpha: 0, y: 20, transformOrigin: "0 0", skewY: "-3deg"});
    TweenMax.to(infos[counter], 0.7, {autoAlpha: 0, y: 20, transformOrigin: "0 0", skewY: "-3deg"});

    counter--;
    if(counter === -1) counter = backgrounds.length - 1;

    TweenMax.fromTo(backgrounds[counter], 0.7, {autoAlpha: 0}, {autoAlpha: 0.3});
    TweenMax.fromTo(squares[counter], 0.7, {autoAlpha: 0, y: -20, transformOrigin: "0 0", skewY: "-3deg"},
      {autoAlpha: 1, y: 0, skewY: "0"});
    TweenMax.fromTo(infos[counter], 0.7, {
      autoAlpha: 0,
      y: -20,
      transformOrigin: "0 0",
      skewY: "-3deg"
    }, {autoAlpha: 1, y: 0, skewY: "0"});

    //counter--;
  });
});


if ($(".sort-section").length > 0) {
  var elem = document.querySelector('.sort-section__content'),
    iso = new Isotope(elem, {
      itemSelector: '.sort-section__card',
      layoutMode: 'fitRows',
      /*fitRows: {
          gutter: '.gutter-sizer-5'
      }*/
    });

  $(".staff-section").each(function () {
    var filterValue = $("button.sort-section__tab.active ").attr("data-filter");
    iso.arrange({filter: filterValue});
  });

  if ($(elem).hasClass(".clients-page__clients-list")) {

  }

  $(".sort-section__tab").click(function () {
    if ($(this).hasClass("active")) return;

    var filterValue = $(this).attr("data-filter");
    iso.arrange({filter: filterValue});
    $(".sort-section__tab").each(function () {
      $(this).removeClass("active");
    });

    $(this).addClass("active");
    AOS.refreshHard();
  });

}

var articlesOffsets = [];


function remToPix(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};


var isVisible = function (el, offset) {
  if (offset == null) offset = 0;
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop + offset;
    left += el.offsetLeft;
  }

  return (
    top < (window.pageYOffset + window.innerHeight) &&
    left < (window.pageXOffset + window.innerWidth) &&
    (top + height) > window.pageYOffset &&
    (left + width) > window.pageXOffset
  );
};

/*(function () {
    var _overlay = document.getElementById('side_form');
    var _clientY = null; // remember Y position on touch start

    _overlay.addEventListener('touchstart', function (event) {
        if (event.targetTouches.length === 1) {
            // detect single touch
            _clientY = event.targetTouches[0].clientY;
        }
    }, false);

    _overlay.addEventListener('touchmove', function (event) {
        if (event.targetTouches.length === 1) {
            // detect single touch
            disableRubberBand(event);
        }
    }, false);

    function disableRubberBand(event) {
        var clientY = event.targetTouches[0].clientY - _clientY;

        if (_overlay.scrollTop === 0 && clientY > 0) {
            // element is at the top of its scroll
            event.preventDefault();
        }

        if (isOverlayTotallyScrolled() && clientY < 0) {
            //element is at the top of its scroll
            event.preventDefault();
        }
    }

    function isOverlayTotallyScrolled() {
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
        return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
    }
}())*/
