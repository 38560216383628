import paper from 'paper';
import {Elastic} from "gsap";

var _ = require('lodash');
//import QuickSettings from 'quicksettings';

var VerticalLines;
(function (VerticalLines) {
    //console.clear();
    var windowWidth = window.innerWidth;
    var log = console.log.bind(console);
    paper.setup(document.querySelector("#vertical-lines-canvas"));
    var project = paper.project;
    var view = paper.view;
    var vw = view.bounds.width;
    var vh = view.bounds.height;
    var lines = [];
    var lineColor = "#cdcdcd";
    var lineWidth = 1;
    var showPoints = true;
    var showMoire = false;
    var yAxisLocked = true;
    var lineSpacing = (windowWidth < 1023) ? (windowWidth < 767) ? windowWidth/6 : 40 : 90;
    var pathSegments = 2;
    var snapDist = 80;
    var repelDist = (windowWidth < 1023) ? (windowWidth < 767) ? 30 : 50 : 80;
    var snapDistSq = Math.pow(snapDist, 2);
    var repelDistSq = Math.pow(repelDist, 2);
    var duration = 1.5;
    var easePeriod = 0.2;
    var easeStrength = 1.4;
    var elasticEase = Elastic.easeOut.config(easeStrength, easePeriod);
    var smoothingNames = "Continuous,Centripetal,Geometric,Chordal,Uniform,None".split(",");
    var smoothingTypes = [
        {type: "continuous"},
        {type: "catmull-rom", factor: 0.5},
        {type: "geometric", factor: 0.4},
        {type: "catmull-rom", factor: 1},
        {type: "catmull-rom", factor: 0},
        {type: "geometric", factor: 0}];

    var pathSmoothing = smoothingTypes[0];
    var debouncedInit = _.debounce(init, 30);
    var center = new paper.Point(vw / 2, vh / 2);
    var mouse = {delta: new paper.Point(0, 0), point: center};
    var repellerRaster = null;
    var repeller = new paper.Shape.Circle({
        radius: repelDist,
        fillColor: "#ffffff",
        center: center
    });

    //repeller.fillColor.alpha = 1;
    var pointShape = new paper.Path.Circle({
        center: [0, 0],
        radius: 8,
        fillColor: "#B0140E",
        strokeColor: "#B0140E"
    });

    var pointSymbol = new paper.Symbol(pointShape.rasterize());
    pointShape.remove();
    var lineRaster = null;
    var lineSymbol = null;
    var lineShape = new paper.Path({
        segments: [[0, 0], [0, vh]],
        strokeColor: lineColor,
        strokeWidth: lineWidth
    });

    var sixthScreen = document.querySelector(".sixth-screen");

    //var settings = QuickSettings.create(5, 5, "Settings", sixthScreen);
    //
    // CREATE SETTINGS
    // ========================================================================
    /*function createSettings() {
        settings.addBoolean("Show Points", showPoints, function (value) {
            pointSymbol.definition.visible = showPoints = value;
        }).addBoolean("Lock Y-Axis", yAxisLocked, function (value) {
            yAxisLocked = value;
        }).addBoolean("Moire Effect", showMoire, function (value) {
            showMoire = value;
        }).addRange("Line Spacing", 3, 250, lineSpacing, 1, function (value) {
            lineSpacing = value;
            debouncedInit();
        }).addRange("Path Segments", 2, 25, pathSegments, 1, function (value) {
            pathSegments = value;
            debouncedInit();
        }).addRange("Repel Distance", 5, 400, repelDist, 1, function (value) {
            repeller.radius = repelDist = value;
            repelDistSq = Math.pow(repelDist, 2);
            if (repelDist > snapDist) {
                settings.setRangeValue("Snap Distance", repelDist + 1);
            }
            rasterizeRepeller();
        }).addRange("Snap Distance", 5, 400, snapDist, 1, function (value) {
            snapDist = value;
            snapDistSq = Math.pow(snapDist, 2);
            if (snapDist < repelDist) {
                settings.setRangeValue("Repel Distance", snapDist - 1);
            }
        }).addRange("Duration", 0.1, 6, duration, 0.1, function (value) {
            duration = value;
        }).addRange("Wave Strength", 1, 2.5, easeStrength, 0.1, function (value) {
            easeStrength = value;
            elasticEase = Elastic.easeOut.config(easeStrength, easePeriod);
        }).addRange("Wave Length", 0.1, 1, easePeriod, 0.1, function (value) {
            easePeriod = value;
            elasticEase = Elastic.easeOut.config(easeStrength, easePeriod);
        }).addDropDown("Path Smoothing", smoothingNames, function (value) {
            pathSmoothing = smoothingTypes[value.index];
        });
    }*/

    //
    // INIT
    // ========================================================================
    function init() {
        vw = view.bounds.width;
        vh = view.bounds.height;
        for (var _i = 0, lines_1 = lines; _i < lines_1.length; _i++) {
            //if (window.CP.shouldStopExecution(0)) break;
            var line = lines_1[_i];
            line.destroy();
        }
        //window.CP.exitedLoop(0);
        lines = [];
        rasterizeLine();
        for (var i = 0, x = windowWidth*0.05; i < 7; i++, x += lineSpacing) {

            //if (window.CP.shouldStopExecution(1)) break;
            lines.push(new Line(i, x));
        }
        //window.CP.exitedLoop(1);
        //repellerRaster.bringToFront();
    }

    //
    // CONTROL POINT
    // ========================================================================
    var ControlPoint = /** @class */function () {
        function ControlPoint(index, line, path, start, canMove) {
            this.index = index;
            this.line = line;
            this.path = path;
            this.start = start;
            this.shape = null;
            this.animation = null;
            this.connected = false;
            if (canMove) {
                this.shape = pointSymbol.place(start);
                this.shape.parent = path.parent;
                this.animation = new TimelineLite();
            }
        }

        ControlPoint.prototype.destroy = function () {
            this.animation && this.animation.kill();
            for (var prop in this) {
                this[prop] = null;
            }
        };
        ControlPoint.prototype.refresh = function () {
            var isAnimating = this.animation.isActive();
            var segment = this.path.segments[this.index];
            var dist1 = mouse.point.getDistance(segment.point, true);
            var close = this.start.isClose(this.shape.position, 0.1);

            var start = this.start;
            if (dist1 < repelDistSq) {
                this.animation.clear();
                var dist2 = mouse.point.getDistance(start, true);
                if (dist2 > snapDistSq) {
                    this.snapBack();
                } else {
                    var direction = segment.point.subtract(mouse.point).normalize();
                    var offset = direction.multiply(repelDist - 1).add(mouse.point);
                    segment.point.x = offset.x;
                    segment.point.y = yAxisLocked ? segment.point.y : offset.y;
                    this.shape.position = segment.point;
                }
            } else {
                if (!isAnimating && !close) {
                    this.snapBack();
                }
            }
            return isAnimating || !close;
        };
        ControlPoint.prototype.snapBack = function () {
            var start = this.start;
            var shape = this.shape.position;
            var point = this.path.segments[this.index].point;
            this.animation.clear();
            this.animation.to([point, shape], duration, {
                    x: start.x,
                    y: start.y,
                    ease: elasticEase
                },
                0);
        };
        return ControlPoint;
    }();
    //
    // LINE
    // ========================================================================
    var Line = /** @class */function () {
        function Line(index, x) {
            this.index = index;
            this.x = x;
            this.controlPoints = [];
            this.group = new paper.Group();
            this.path = new paper.Path({
                parent: this.group,
                strokeColor: lineColor,
                strokeWidth: lineWidth
            });

            this.pathSymbol = lineSymbol.place([this.x, view.center.y]);
            this.pathSymbol.parent = this.group;
            this.pathSymbol.visible = showMoire;
            var segments = [];
            for (var i = 0; i <= pathSegments; i++) {
                //if (window.CP.shouldStopExecution(2)) break;
                var y = i / pathSegments * vh;
                var start = new paper.Point(x, y);
                var canMove = i && i !== pathSegments;
                var controlPoint = new ControlPoint(i, this, this.path, start, canMove);
                this.controlPoints.push(controlPoint);
                segments.push(start);
            }
            //window.CP.exitedLoop(2);
            this.path.addSegments(segments);
        }

        Line.prototype.destroy = function () {
            this.group.remove();
            for (var _i = 0, _a = this.controlPoints; _i < _a.length; _i++) {
                //if (window.CP.shouldStopExecution(3)) break;
                var controlPoint = _a[_i];
                controlPoint.destroy();
            }
            //window.CP.exitedLoop(3);
            for (var prop in this) {
                this[prop] = null;
            }
        };
        Line.prototype.update = function () {
            var hasChanges = false;
            var total = this.path.segments.length - 1;
            for (var i = 1; i < total; i++) {
                //if (window.CP.shouldStopExecution(4)) break;
                if (this.controlPoints[i].refresh()) {
                    hasChanges = true;
                }
            }
            //window.CP.exitedLoop(4);
            if (hasChanges) {
                this.path.visible = true;
                this.pathSymbol.visible = showMoire;
                this.smoothPath();
            } else {
                this.path.visible = false;
                this.pathSymbol.visible = true;
            }
        };
        Line.prototype.smoothPath = function () {
            this.path.smooth(pathSmoothing);
        };
        return Line;
    }();
    //
    // RASTERIZE LINE
    // ========================================================================
    function rasterizeLine() {
        if (lineRaster) {
            lineRaster.remove();
        }
        lineShape.visible = true;
        lineShape.segments = [[0, 0], [0, vh]];
        lineRaster = lineShape.rasterize();
        lineSymbol = new paper.Symbol(lineRaster);
        lineShape.visible = false;
    }

    //
    // RASTERIZE REPELLER
    // ========================================================================
    function rasterizeRepeller() {
        if (repellerRaster) {
            repellerRaster.remove();
        }
        repeller.visible = true;
        repellerRaster = repeller.rasterize();
        repellerRaster.position = mouse.point;
        repeller.visible = false;
        repellerRaster.blendMode = "difference";
    }

    //
    // UPDATE
    // ========================================================================
    function update() {
        for (var _i = 0, lines_2 = lines; _i < lines_2.length; _i++) {
            // if (window.CP.shouldStopExecution(5)) break;
            var line = lines_2[_i];
            line.update();
        }
        // window.CP.exitedLoop(5);
        repellerRaster.position = mouse.point;
    }

    //
    // MOUSE MOVE
    // ========================================================================
    function mouseMove(event) {
        mouse = event;
    }

    //createSettings();
    rasterizeRepeller();
    init();
    view.on({
        frame: update,
        mousemove: mouseMove,
        resize: debouncedInit
    });

})(VerticalLines || (VerticalLines = {}));
